p {
  margin: 0;
}

:root {
  --movement-color: #98b5c5;
  --still-color: #b9d79d;
  --shape-color: #f5d78a;
  --playhouse-color: #dc9e93;
  --about-color: #c995bb;
}

.App {
  text-align: center;
}

#landing-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #fff9f1;
  /*display: grid;*/
  flex-direction: column;
}

.landing-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 12vw;
  max-height: 150px;
  min-height: 75px;
}

.top-wrapper-middle {
  margin: auto;
}

.logo-wrapper {
  width: 10vw;
  margin-top: auto;
  margin-bottom: auto;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  font-family: new-spirit;
}

.logo-wrapper-top {
  margin-top: auto;
  font-size: 2em;
  text-align: left;
  letter-spacing: 0px;
}

.logo-wrapper-bottom {
  margin-bottom: auto;
  font-size: 2em;
  text-align: left;
  letter-spacing: 0px;
  margin-top: -10px;
}

.word-wheel-wrapper {
  width: fit-content;
  height: fit-content;
  margin: auto;
}

.landing-word-wheel {
  width: 10vw;
  height: 10vw;
  max-height: 150px;
}

.invisible {
  display: none;
}

#movement-bkg-img {
  grid-row: 1;
  grid-column: 1;
  width: 100vw;
  height: 100vh;
  display: none;
}

#shape-bkg-img {
  grid-row: 1;
  grid-column: 1;
  width: 100vw;
  height: 100vh;
  display: none;
}

#still-bkg-img {
  grid-row: 1;
  grid-column: 1;
  width: 100vw;
  height: 100vh;
  display: none;
}

#playhouse-bkg-img {
  grid-row: 1;
  grid-column: 1;
  width: 100vw;
  height: 100vh;
  display: none;
}

#movement { 
  font-family: new-spirit;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  font-size: 70px;
  margin: auto;
}

#movement:hover {
  cursor: pointer;
}

#still {
  font-family: new-spirit;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  font-size: 70px;
  margin: auto;
}

#still:hover {
  cursor: pointer;
}

#shape {
  font-family: new-spirit;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  font-size: 70px;
  margin: auto;
}

#shape:hover {
  cursor: pointer;
}

#playhouse {
  font-family: new-spirit;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  font-size: 70px;
  margin: auto;
}

#playhouse:hover {
  cursor: pointer;
}

.landing-menu-item {
  width: 40vw;
  height: 12vh;
  margin: auto;
}

.landing-menu-double-item {
  width: 40vw;
  height: 12vh;
  margin: auto;
  display: flex;
  margin-left: auto;
}

.landing-menu-wrapper {
  width: 60vw;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 30vh;
  grid-row: 1;
  grid-column: 1;
}

#shape-wrapper {
  width: 17vw;
  height: inherit;
  padding-left: 8vw;
}

#still-wrapper {
  width: 17vw;
  height: inherit;
}

.page-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
}

.movement-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 10vw;
  max-height: 150px;
  background-color: var(--movement-color);
  min-height: 75px;
}

.shape-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 10vw;
  max-height: 150px;
  background-color: var(--shape-color);
  min-height: 75px;
}

.shape-body-wrapper {
  height: "300vh";
  background: var(--shape-color);
}

.still-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 10vw;
  max-height: 150px;
  background-color: var(--still-color);
  min-height: 75px;
}

.still-body-wrapper {
  min-height: 100vh;
  background: var(--still-color);
}

.playhouse-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 10vw;
  max-height: 150px;
  background-color: var(--playhouse-color);
  min-height: 75px;
}

.playhouse-body-wrapper {
  min-height: 100vh;
  background: var(--playhouse-color);
}

.about-top-wrapper {
  display: flex;
  height: fit-content;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
  height: 12vw;
  max-height: 150px;
  /*background-color: var(--about-color);*/
  background-color: #fff9f1;
  min-height: 75px;
}

.about-body-wrapper {
  min-height: 100vh;
  background: #fff9f1;
}

.page-logo-wrapper {
  width: 10vw;
  max-height: 150px;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  font-family: new-spirit;
}

.page-logo-wrapper:hover {
  cursor: pointer;
}

.page-wrapper-top {
  margin-top: auto;
  font-size: 2em;
  text-align: left;
  letter-spacing: 0px;
}

.page-wrapper-bottom {
  margin-bottom: auto;
  font-size: 2em;
  text-align: left;
  letter-spacing: 0px;
  margin-top: -10px;
}

.page-word-wheel-wrapper {
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: grid;
}

.page-word-wheel {
  width: 10vw;
  height: 10vw;
  max-height: 150px;
  grid-column: 1;
  grid-row: 1;
}

.page-word-wheel-icon {
  grid-column: 1;
  grid-row: 1;
  width: 5vw;
  height: 5vw;
  margin: auto;
}

.ytp-button {
  display: none !important;
}

.ytp-large-play-button {
  display: none !important;
}

.movement-body-wrapper {
  display: flex;
  padding-top: 2vh;
  flex-direction: column;
  background: var(--movement-color);
}

.movement-body-row {
  display: flex;
  margin: auto;
  margin-bottom: 0.5vw;
}

.movement-iframe {
  width: 48vw;
  grid-column: 1;
  grid-row: 1;
}

.movement-iframe:hover {
  cursor: pointer;
}

.movement-iframe-left {
  margin-left: 1vw;
  margin-right: 0.5vw;
}

.movement-iframe-right {
  margin-right: 1vw;
}

.movement-item-text-left {
  cursor: pointer;
}

.movement-item-text-right:hover {
  cursor: pointer;
}

.movement-item-wrapper {
  display: grid;
}

.movement-item-wrapper:hover {
}

.movement-item-text-left {
  display: none;
  grid-column: 1;
  grid-row: 1;
  width: 48vw;
  margin-left: 1vw;
  margin-right: 0.5vw;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: saturate(110%);
}

.movement-item-text-right {
  display: none;
  grid-column: 1;
  grid-row: 1;
  width: 48vw;
  margin-right: 1vw;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: saturate(110%);
}

.movement-item-text-left p {
  margin: auto;
  color: white;
  font-family: new-spirit;
  font-weight: 700;
}

.movement-item-text-right p {
  margin: auto;
  color: white;
  font-family: new-spirit;
  font-weight: 700;
}

.landing-menu-mobile-wrapper {
  display: none;
}

.landing-menu-mobile-top-wrapper {
  display: none;
}

#undertheinfluence {
  background: rgba(75, 0, 43, 0.6);
}

#caffeine {
  background: rgba(135, 155, 197, 0.6);
}

#stacy {
  background: rgba(231, 134, 122, 0.6);
}

#wyai {
  background: rgba(57, 118, 143, 0.6);
}

#aihrn {
  background: rgba(240, 154, 202, 0.6);
}

#slowdown {
  background: rgba(157, 219, 212, 0.6) !important;
}

#uandus {
  background: rgba(222, 174, 99, 0.6);
}

#iiwu {
  background: rgba(64, 85, 103, 0.6);
}

#roll {
  background: rgba(240, 45, 20, 0.6);
}

#thenitsgoodbye {
  background: rgba(187, 91, 154, 0.6);
}

#strangersforever {
  background: rgba(239, 99, 137, 0.6);
}

#bowuv {
  background: rgba(72, 16, 13, 0.6);
}

#bowul {
  background: rgba(0, 28, 94, 0.6);
}

#jessiemurph-1 {
  background: rgba(147, 0, 0, 0.6);
}

#slushpuppy-1 {
  background: rgba(1, 220, 222, 0.6);
}

#changemymind {
  background: rgba(98, 35, 17, 0.6);
}

#first {
  background: rgba(155, 172, 189, 0.6);
}

#wildflowers {
  background: rgba(0, 0, 0, 0.6);
}

#canuhearme {
  background: rgba(189, 167, 148, 0.6);
}

#dftb {
  background: rgba(180, 103, 235, 0.6);
}

#coping {
  background: rgba(110, 106, 114, 0.6);
}

#sowhat {
  background: rgba(22, 129, 227, 0.6);
}

#atlanta {
  background: rgba(0, 0, 0, 0.6);
}

#skating {
  background: rgba(234, 227, 217, 0.6);
}

#neffex {
  background: rgb(140, 83, 61, 0.6);
}

#slushpuppy-2 {
  background: rgba(27, 33, 2, 0.6);
}

.top-wrapper-right {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 3vw;
}

.in-page-nav-wrapper {
  margin: auto;
  display: flex;
  margin-top: 0;
}

.top-mobile-nav {
  padding-top: 2vh;
  padding-bottom: 2vh;
  display: none;
}

.in-page-nav-item {
  font-family: new-spirit;
}

.in-page-nav-cart-link {
  margin-left: 5vw;
}

.in-page-nav-cart-link:hover {
  cursor: pointer;
}

.in-page-nav-item:hover {
  cursor: pointer;
  font-family: new-spirit;
  font-weight: 700;
}

.movement-vid-iframe-wrapper {
  height: 100%;
  width: 100vw;
  display: flex;
}

.movement-vid-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--movement-color);
}

.movement-vid-iframe {
  margin: auto;
  width: 80% !important;
  height: 85%;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.movement-vid-info {
  display: flex;
  flex-direction: column;
}

.movement-vid-info {
  width: 50vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.movement-vid-director {
  width: fit-content;
  margin: auto;
  margin-bottom: 2vh;
}

.movement-vid-dop {
  width: fit-content;
  margin: auto;
  margin-bottom: 2vh;
}

.movement-vid-producer {
  width: fit-content;
  margin: auto;
  margin-bottom: 2vh;
}

.shape-photo-row {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-bottom: 4vh;
}

.shape-photo-row-first {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-bottom: 4vh;
  padding-top: 4vh;
}

.shape-photo-half {
  width: 46vw;
  height: 30vw;
  object-fit: cover;
  grid-row: 1;
  grid-column: 1;
}

.shape-photo-half:hover {
  cursor: pointer;
}

.shape-photo-half:hover + p {
  display: flex;
}

.shape-photo-big {
  width: 60vw;
  height: 45vw;
  object-fit: cover;
  grid-row: 1;
  grid-column: 1;
}

.shape-photo-big:hover {
  cursor: pointer;
}

.shape-photo-big:hover + p {
  display: flex;
}

.shape-photo-small:hover {
  cursor: pointer;
}

.shape-photo-small:hover + p {
  display: flex;
}

.shape-img-text:hover {
  display: flex;
}

.shape-photo-big-left {
  display: grid;
}

.shape-photo-big-right {
  display: grid;
}

.shape-photo-half-left {
  display: grid;
  margin-right: auto;
}

.shape-photo-half-right {
  display: grid;
}

.shape-img-text {
  grid-row: 1;
  grid-column: 1;
  font-size: 40px;
  font-family: new-spirit;
  margin: auto;
  color: white;
  display: none;
}

.shape-photo-smallest {
  display: grid;
}

.shape-photo-small-wrapper {
  margin: auto;
  width: 32.5vw;
  height: 45vw;
  display: flex;
  grid-row: 1;
  grid-column: 1;
  flex-direction: column;
}

.shape-photo-small {
  margin-left: auto;
  margin-bottom: auto;
  width: 32.5vw;
  height: 21vw;
  object-fit: cover;
  grid-column: 1;
  grid-row: 1;
}

.react-parallax-content {
  margin: auto !important;
}

/*.react-parallax-bgimage {
  object-position: 0 -35vh !important;
}*/

.shape-project-x {
  margin: auto;
  margin-right: 5vw;
  color: white;
  font-family: new-spirit;
  font-size: 36px;
}

.shape-project-x:hover {
  cursor: pointer;
  font-family: new-spirit;
  font-weight: 700;
}

.parallax-text {
  font-size: 42px;
  color: white;
  font-family: new-spirit;
}

.shape-photos-row {
  display: flex;
  padding-top: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.shape-photos-row-double {
  display: flex;
  padding-top: 5vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.shape-photos-title {
  font-size: 28px;
  font-family: new-spirit;
}

.shape-photo {
  margin-left: auto;
}

.shape-photo-project-big {
  margin: auto;
  width: 90vw;
  height: fit-content;
  margin-bottom: 5vw;
}

.shape-photos-wrapper {
  background-color: var(--shape-color);
}

.shape-photo-lv {
  margin: auto;
  height: 50vw;
}

.shape-photo-remi {
  width: 43vw;
  height: fit-content;
  margin-left: auto;
}

.pp-cocaine-video {
  margin-left: auto;
  padding-bottom: 5vw;
  width: 43vw;
  height: fit-content;
}

.pp-cocaine-iframe {
  width: 43vw;
  height: 30vw;
}

.about-paragraph {
  font-family: new-spirit;
  margin: auto;
  width: 60vw;
  font-size: 22px;
  text-align: center;
  /*margin-bottom: 5vw;*/
}

.featured-in-title {
  width: 60vw;
  margin: auto;
  font-family: new-spirit;
  font-size: 22px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2vw;
}

.featured-in-list {
  width: 65vw;
  display: flex;
  margin-top: 0;
  margin-bottom: 5vw;
  margin-left: auto;
  margin-right: auto;
  font-family: new-spirit;
  font-size: 22px;
  text-align: center;
}

.featured-in-item {
  width: 8vw;
  height: fit-content;
  margin: auto;
  min-width: 100px;
}

.about-subparagraph {
  font-family: new-spirit;
  margin: auto;
  margin-top: 5vw;
  width: 60vw;
  font-size: 22px;
  text-align: center;
  margin-top: 0;
}

.about-text-wrapper {
  height: 80vh;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.bill-nye-top-tt {
  margin-left: auto;
  width: 43vw;
}

.bill-nye-mid-tt {
  margin: auto;
  margin-left: 2.5vw;
  margin-top: 0;
  width: 43vw;
}

.page-slogan-wrapper {
  display: flex;
  width: 100vw;
  height: fit-content;
  padding-bottom: 2.5vh;
}

.page-slogan {
  font-family: new-spirit;
  font-size: 16px;
  color: black;
  margin: auto;
}

.close-icon {
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
}

.close-icon:hover {
  -webkit-transform: rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.playhouse-comingsoon-wrapper {
  width: 100vw;
  display: flex;
}

.playhouse-comingsoon {
  font-family: new-spirit;
  font-size: 20px;
  margin: auto;
  margin-top: 10vw;
}

.playhouse-store-wrapper {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  padding-top: 4vh;
}

.product-outer-wrapper {
  width: 33vw;
  height: 33vw;
  margin: auto;
}

.product-inner-wrapper {
  width: 80%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.product-info-wrapper {
  height: 10%;
  display: flex;
}

.product-popup-body {
  display: flex;
  padding: 2vw;
}

.product-popup-img {
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-popup-info {
  height: fit-content;
  margin: auto;
}

.product-popup-title {
  font-family: new-spirit;
  font-weight: 700;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: 1vw;
  margin-left: 2vw;
}

.product-popup-size {
  font-family: new-spirit;
  font-size: 14px;
  height: fit-content;
  width: fit-content;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 2vw;
}

.product-popup-shopping-btn {
  background-color: white;
  color: black;
  border-color: black;
  border-radius: 0;
  margin: auto;
  margin-right: 1vw;
  width: 45%;
  height: 8vh;
  font-family: new-spirit;
}

.product-popup-cart-btn {
  background-color: black;
  color: white;
  border-color: black;
  border-radius: 0;
  margin: auto;
  margin-left: 1vw;
  width: 45%;
  height: 8vh;
  font-family: new-spirit;
}

.modal-title {
  font-family: new-spirit;
}

.product-popup-shopping-btn:hover {
  border-color: black;
  background-color: white;
  color: black;
}

.product-popup-cart-btn:hover {
  border-color: black;
  background-color: black;
}

a {
  text-decoration: none;
}

.product-page-inner-wrapper {
  width: 80%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.product-image-frame {
  max-width: 90%;
  max-height: 80%;
  margin: auto;
  margin-bottom: 2vh;
  background: white;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-image {
  width: 100%;
  height: 100%;
}

.product-page-image-frame {
  max-width: 90%;
  max-height: 80%;
  margin: auto;
  background: white;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.product-page-image {
  width: 100%;
  height: 100%;
}

.product-title {
  color: black;
  text-decoration: none;
  margin: auto;
  margin-top: auto;
  font-family: new-spirit;
}

.product-page-title {
  color: black;
  font-family: new-spirit;
  font-size: 22px;
  margin-bottom: 2vh;
}

.product-page-artist {
  color: black;
  font-family: new-spirit;
  font-size: 14px;
  margin-bottom: 2vh;
}

.product-page-description {
  font-size: 14px;
  margin-bottom: 2vh;
  max-width: 70%;
}

.product-page-price {
  color: black;
  margin-bottom: 2vh;
}

.product-information-wrapper {
  height: fit-content;
  margin: auto;
  margin-top: 8vh;
}

.playhouse-product-page-wrapper {
  width: 90vw;
  display: flex;
  margin: auto;
}

.product-page-left-wrapper {
  width: 60%;
  height: 90vh;
  margin: auto;
}

.product-page-right-wrapper {
  width: 40%;
  height: 90vh;
  margin: auto;
  display: flex;
}

.product-page-addtocart-btn {
  width: fit-content;
  padding: 1vw;
  background-color: white;
  color: black;
  border-radius: 5px;
}

.product-page-addtocart-btn:hover {
  cursor: pointer;
}

.product-page-addtocart-title {
  font-family: new-spirit;
}

.product-variant-selection {
  width: 70%;
  display: flex;
}

.product-variant-selection:hover {
  cursor: pointer;
}

.product-variant-size {
  font-family: new-spirit;
  margin-right: auto;
  padding: 1vw;
}

.product-variant-price {
  font-family: new-spirit;
  padding: 1vw;
}

.playhouse-store-loading {
  font-family: new-spirit;
  width: fit-content;
  margin: auto;
}

.cart-wrapper {
  display: flex;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.cart-body-wrapper {
  min-height: 100vh;
  background: var(--playhouse-color);
  display: flex;
  flex-direction: column;
}

.cart-title {
  font-family: new-spirit;
  font-weight: 700;
  font-size: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3vw;
}

.cart-item-img {
  width: 20vw;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cart-item-title {
  font-family: new-spirit;
  font-weight: 700;
  font-size: 14px;
}

.cart-item-quantity {
  font-family: new-spirit;
  font-size: 14px;
}

.cart-item-price {
  font-family: new-spirit;
  font-size: 14px;
}

.cart-item-remove {
  font-family: new-spirit;
  font-size: 14px;
  text-decoration: underline;
}

.cart-item-remove:hover {
  cursor: pointer;
  color: white;
}

.cart-item-wrapper {
  display: flex;
}

.cart-left-wrapper {
  width: 50vw;
  margin-right: auto;
}

.cart-item-left {
  margin-right: auto;
}

.cart-item-center {
  margin: auto;
  margin-top: 0;
}

.cart-item-right {
  margin: auto;
  margin-top: 0;
}

.cart-right-wrapper {
  border: 1px black solid;
  width: 30vw;
  padding: 4vw;
  max-height: 45vh;
  display: flex;
  flex-direction: column;
}

.cart-right-title {
  font-family: new-spirit;
  font-weight: 700;
  font-size: 20px;
  margin: auto;
  margin-top: -2vh;
  margin-bottom: 2vh;
}

.cart-right-subtotal-wrapper {
  width: 100%;
  display: flex;
}

.cart-right-subtotal {
  font-family: new-spirit;
  font-weight: 700;
  font-size: 20px;
  margin: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  margin-left: 0;
}

.cart-right-subtotal-price {
  font-family: new-spirit;
  font-weight: 700;
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 20px;
}

.cart-right-shipping-wrapper {
  width: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom: 2vh;
}

.cart-right-shipping {
  font-family: new-spirit;
  font-size: 14px;
  margin: auto;
  margin-top: 0;
  margin-left: 0;
}

.cart-right-shipping-price {
  font-family: new-spirit;
  font-size: 14px;
}

.cart-right-checkout-btn {
  color: white;
  background-color: black;
  width: 80%;
  display: flex;
  padding: 2vh 2vh;
  margin: auto;
  margin-bottom: 0;
}

.cart-right-checkout-btn:hover {
  cursor: pointer;
  color: black;
  background-color: white;
}

.cart-right-checkout-txt {
  font-family: new-spirit;
  font-size: 14px;
  margin: auto;
}

#under-construction-wrapper {
  display: flex;
  margin: auto;
  /*flex-direction: column;*/
  /*grid-row: 2;
  grid-column: 1;*/
  margin: auto;
  margin-top: 20vh;
}

#under-construction-text {
  font-family: new-spirit;
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  font-size: 40px;
  margin: auto;
}

#under-construction-about-link-wrapper {
  display: flex;
  margin: auto;
  width: fit-content;
}

#under-construction-about-link-wrapper:hover {
  cursor: pointer;
}

#under-construction-about-link {
  font-family: new-spirit;
  font-weight: 300;
  width: fit-content;
  height: fit-content;
  font-size: 20px;
  margin: auto;
}

#under-construction-link:hover {
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  #movement {
    font-size: 60px;
  }

  #still {
    font-size: 60px;
  }

  #shape {
    font-size: 60px;
  }

  #playhouse {
    font-size: 60px;
  }
}

@media screen and (max-width: 950px) {
  #movement {
    font-size: 50px;
  }

  #still {
    font-size: 50px;
  }

  #shape {
    font-size: 50px;
  }

  #playhouse {
    font-size: 50px;
  }
}

@media screen and (max-width: 850px) {
  #movement {
    font-size: 45px;
  }

  #still {
    font-size: 45px;
  }

  #shape {
    font-size: 45px;
  }

  #playhouse {
    font-size: 45px;
  }

  .shape-photo-row-first {
    padding-top: 2vh !important;
  }

  .in-page-nav-wrapper {
    font-size: 12px;
  }

  .top-mobile-nav {
    font-size: 12px;
  }

  .product-title {
    font-size: 12px;
  }

  .about-paragraph {
    font-size: 18px;
    width: 80vw;
  }

  .playhouse-comingsoon {
    font-size: 18px;
  }

  .featured-in-title {
    font-size: 18px;
  }

  .featured-in-item {
    padding-top: 2vh;
  }

  .about-subparagraph {
    font-size: 18px;
  }

  .page-slogan {
    font-size: 12px;
  }

  .movement-vid-iframe {
    height: 75%;
  }
}

@media screen and (max-width: 770px) {
  .playhouse-store-wrapper {
    flex-direction: column;
  }

  .product-outer-wrapper {
    width: 90vw;
    height: 90vw;
  }

  .featured-in-list {
    flex-direction: column;
  }

  .page-slogan-wrapper {
    padding-top: 2vh;
  }

  .bill-nye-top-tt {
    margin: auto;
    width: 85vw;
  }

  .bill-nye-mid-tt {
    margin: auto !important;
    width: 85vw;
  }

  .shape-photos-row {
    flex-direction: column;
    margin: auto;
    padding-bottom: 5vw;
  }

  .shape-photos-row-double {
    flex-direction: column;
    margin: auto;
  }

  .shape-photo-remi {
    width: 80vw;
    height: fit-content;
    margin: auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 5vw !important;
  }

  .shape-photo-lv {
    margin: auto !important;
    width: 80vw;
    height: fit-content;
  }

  .shape-photo {
    margin: auto !important;
    width: 80vw;
    height: fit-content;
  }

  .shape-photos-title {
    width: fit-content;
    margin: auto !important;
    margin-top: 5vw !important;
    margin-bottom: 5vw !important;
  }

  .shape-photo-row {
    flex-direction: column;
    height: auto;
    padding-bottom: 0;
  }

  .shape-photo-row-first {
    flex-direction: column;
    height: auto;
    padding-bottom: 0;
  }

  .shape-img-text {
    display: block;
    font-size: 32px;
  }

  .shape-photo-big {
    width: 90vw;
    height: 70vw;
    margin: auto !important;
    padding-bottom: 4vh;
  }

  .shape-photo-half-left {
    margin: auto;
    padding-bottom: 4vh;
  }

  .shape-photo-half-right {
    padding-bottom: 4vh;
  }

  .shape-photo-small {
    width: 90vw;
    height: 70vw;
    margin: auto;
  }

  .shape-photo-half {
    width: 90vw;
    height: 70vw;
    margin: auto !important;
  }

  .shape-photo-small-wrapper {
    width: inherit;
    height: inherit;
  }

  .shape-photo-smallest {
    margin: auto !important;
    padding-bottom: 4vh !important;
  }

  .top-mobile-nav {
    width: 100vw;
    display: flex;
    flex-direction: row;
  }

  .mobile-nav-wrapper {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .in-page-nav-item {
    width: fit-content;
  }

  .top-wrapper-middle {
    display: none;
  }

  .top-wrapper-right {
    margin-left: auto;
  }

  .movement-body-row {
    display: flex;
    flex-direction: column;
  }

  .movement-iframe-left {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5vw;
    width: 95vw;
  }

  .movement-iframe-right {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5vw;
    width: 95vw;
  }

  .movement-body-row {
    margin-bottom: 0;
  }

  .movement-item-text-left {
    margin-left: auto;
    margin-right: auto;
    width: 95vw;
  }

  .movement-item-text-right {
    margin-left: auto;
    margin-right: auto;
    width: 95vw;
  }

  .landing-mobile-wrapper {
    display: grid;
  }

  .landing-menu-mobile-wrapper {
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 1;
  }

  .landing-menu-mobile-top-wrapper {
    display: block;
    grid-column: 1;
    grid-row: 1;
    height: fit-content;
  }

  .landing-mobile-item {
    width: 100vw;
    height: 80vh;
    display: grid;
  }

  .landing-mobile-item:hover {
    cursor: pointer;
  }

  .landing-mobile-title {
    margin: auto;
    grid-row: 1;
    grid-column: 1;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 36px;
  }

  .landing-mobile-title:hover {
    cursor: pointer;
  }

  .landing-menu-wrapper {
    display: none;
  }

  /*.landing-top-wrapper {
    width: 20vw;
  }*/

  .logo-wrapper {
    width: fit-content;
    margin: auto;
    margin-top: 2vh;
  }

  #mobile-movement {
    display: "grid";
  }

  .mobile-menu-img {
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  #mobile-still {
    background-color: var(--still-color);
  }

  #mobile-shape {
    background-color: var(--shape-color);
  }

  #mobile-playhouse {
    background-color: var(--playhouse-color);
  }
}

@media screen and (max-width: 700px) {
  .movement-vid-iframe-wrapper {
    height: 65%;
  }

  .movement-vid-info {
    margin-top: 5vh;
  }
}

@media screen and (max-width: 650px) {
  .movement-vid-iframe-wrapper {
    height: 60%;
  }
}

@media screen and (max-width: 600px) {
  .movement-vid-iframe-wrapper {
    height: 55%;
  }
}

@media screen and (max-width: 550px) {
  .movement-vid-iframe-wrapper {
    height: 50%;
  }

  .movement-item-text-left p {
    margin: auto;
    color: white;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 14px;
  }

  .movement-item-text-right p {
    margin: auto;
    color: white;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .movement-vid-iframe-wrapper {
    height: 40%;
  }

  .movement-vid-info {
    margin-top: 8vh;
  }
}

@media screen and (max-width: 600px) {
  .cart-title {
    font-size: 22px;
  }

  .cart-right-subtotal {
    font-size: 16px;
  }

  .cart-right-subtotal {
    font-size: 16px;
  }

  .cart-body-wrapper {
    padding-top: 4vh;
    min-height: 90vh;
  }

  .cart-wrapper {
    flex-direction: column;
  }

  .cart-left-wrapper {
    margin-top: 2vh;
    width: 100%;
  }

  .cart-item-img {
    width: 35vw;
    max-height: 45vw;
  }

  .cart-right-wrapper {
    width: 100%;
    border: none;
  }

  .cart-right-title {
    display: none;
  }

  .cart-right-checkout-btn {
    margin-top: 2vh;
  }

  .playhouse-product-page-wrapper {
    flex-direction: column;
  }

  .product-page-left-wrapper {
    height: fit-content;
    margin-top: 4vh;
  }

  .product-page-right-wrapper {
    width: 70vw;
    height: fit-content;
  }

  .product-page-description {
    max-width: none;
  }

  .product-page-addtocart-btn {
    margin: auto;
    width: 25vw;
    display: flex;
  }

  .product-page-addtocart-btn p {
    width: fit-content;
    margin: auto;
  }

  .in-page-nav-wrapper {
    font-size: 10px;
  }

  .top-mobile-nav {
    font-size: 10px;
  }

  .product-title {
    font-size: 10px;
  }

  .playhouse-comingsoon {
    font-size: 16px;
  }

  .about-paragraph {
    font-size: 16px;
  }

  .featured-in-title {
    font-size: 16px;
  }

  .about-subparagraph {
    font-size: 16px;
  }

  .page-slogan {
    font-size: 10px;
  }

  .page-logo-wrapper {
    width: 13vw;
  }

  .page-word-wheel {
    width: 13vw;
    height: 13vw;
  }

  .page-word-wheel-icon {
    width: 7vw;
    height: 7vw;
  }
}

@media screen and (max-width: 450px) {
  .product-popup-info {
    padding: 4vw;
  }

  .movement-vid-iframe-wrapper {
    height: 35%;
  }

  .movement-item-text-left p {
    margin: auto;
    color: white;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 12px;
  }

  .movement-item-text-right p {
    margin: auto;
    color: white;
    font-family: new-spirit;
    font-weight: 700;
    font-size: 12px;
  }

  .page-logo-wrapper {
    width: 16vw;
  }

  .page-word-wheel {
    width: 16vw;
    height: 16vw;
  }

  .page-word-wheel-icon {
    width: 8vw;
    height: 8vw;
  }
}
